import React from 'react';
import Layout from '../layout/Layout';

export default ({ location }: { location: Location }) => {
  return (
    <Layout location={location}>
      <h2>Terms</h2>
    </Layout>
  );
};
